import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({ image, cssClass }) => {
  return (
    <div
      className={
        cssClass
          ? ["module module__image", cssClass].join(" ")
          : "module module__image"
      }
    >
      <div className="module__box">
        {image ? (
          <GatsbyImage
            className="module__image-img"
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt={image.altText}
            title={image.title}
            width={image.mediaDetails.width}
            height={image.mediaDetails.height}
            loading={"lazy"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Image;
